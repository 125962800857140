import flatpickr from "flatpickr";
require("flatpickr/dist/themes/airbnb.css");

$(function() {

	flatpickrInit()

	$(document).on( "change", "#from_date", function() {
		$("#to_date").flatpickr( {allowInput: true,minDate: this.value, defaultDate: $("#to_date").val()} );
	});
	
	$(document).on( "change", "#to_date", function() {
		$("#from_date").flatpickr( {allowInput: true,maxDate: this.value, defaultDate: $("#from_date").val()} );
	});

	$(document).on( "change", "#edit_from_date", function() {
		$("#edit_to_date").flatpickr( {allowInput: true,minDate: this.value, defaultDate: $("#edit_to_date").val()} );
	});
	
	$(document).on( "change", "#edit_to_date", function() {
		$("#edit_from_date").flatpickr( {allowInput: true,maxDate: this.value, defaultDate: $("#edit_from_date").val()} );
	});
});


// initialize new ajax .flatpickr
document.addEventListener("ajax:success", (event) => {
	flatpickrInit()
});

// initialize new jquery ajax .flatpickr
$(document).ajaxSuccess(function() {
	flatpickrInit()
});

function flatpickrInit(){
    $(".flatpickr").flatpickr({
		allowInput: true,
		// workaround for bug where initializing flatpicker more than once will blank0-out previously set value
		// https://github.com/flatpickr/flatpickr/issues/1148
		onReady: function(selectedDates, dateStr, instance) {
			//console.log("hellos")
            $(".flatpickr").toggleClass('flatpickr', 'flatpickr-init');
        },
    });
}